.home {
  background: url("../images/computer.jpg");
  background-size: cover;
  height: 100vh;
}
.home2 {
  background-color: #0044ff60;
}

.wrapper {
  margin-top: 5rem;
  width: 100%;
  padding: 0 2rem;
  text-align: center;
}

.polaroid {
  background: rgb(24, 74, 180);
  padding: 3rem;
  box-shadow: 0 0.2rem 1.2rem #0044ff60;
  border-radius: 20px;
}
.polaroid > img {
  max-width: 100%;
  height: auto;
}
.caption {
  font-size: 1.8rem;
  text-align: center;
  line-height: 2em;
}
.item {
  width: 30%;
  display: inline-block;
  margin-top: 2rem;
  filter: grayscale(40%);
}
.item .polaroid:before {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.35s;
}
.item:nth-of-type(4n + 1) {
  /* transform: scale(0.8, 0.8) rotate(5deg); */
  transition: all 0.35s;
}
.item:nth-of-type(4n + 1) .polaroid:before {
  /* transform: rotate(6deg); */
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem #2331f5;
}
.item:nth-of-type(4n + 2) {
  /* transform: scale(0.8, 0.8) rotate(-5deg); */
  transition: all 0.35s;
}
.item:nth-of-type(4n + 2) .polaroid:before {
  /* transform: rotate(-6deg); */
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem #0044ff60;
}
.item:nth-of-type(4n + 4) {
  /* transform: scale(0.8, 0.8) rotate(3deg); */
  transition: all 0.35s;
}
.item:nth-of-type(4n + 4) .polaroid:before {
  /* transform: rotate(4deg); */
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem #0044ff60;
}
.item:nth-of-type(4n + 3) {
  /* transform: scale(0.8, 0.8) rotate(-3deg); */
  transition: all 0.35s;
}
.item:nth-of-type(4n + 3) .polaroid:before {
  /* transform: rotate(-4deg); */
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem #0044ff60;
}
.item:hover {
  filter: none;
  /* transform: scale(1, 1) rotate(0deg) !important; */
  transition: all 0.35s;
}
.item:hover .polaroid:before {
  content: "";
  position: absolute;
  z-index: -1;
  /* transform: rotate(0deg); */
  height: 90%;
  width: 90%;
  bottom: 0%;
  right: 5%;
  box-shadow: 0 1rem 3rem #0044ff60;
  transition: all 0.35s;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 959px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .wrapper {
    margin-top: 5rem;
    width: 100%;
    padding: 0;
  }

  .polaroid {
    width: 100%;
    padding: 0.1rem;
  }

  .item {
    width: 75%;
  }
}
