#root, html, body{
  color: #000000;
  margin:0;
}

body{
   color: #000000;
}

*{
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

 